import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;

//导入公共样式
import "./assets/css/default.css"; //default.css
import "./assets/css/animate.css"; //animate.css
import "./assets/css/common.css"; //common.css
import "./assets/css/table.css"; //table.css

//引入iconfont字体库
import "./assets/iconfont/iconfont.css"; //iconfont.css
import "./assets/iconfont/iconfont.ttf"; //iconfont.ttf
import "./assets/iconfont/iconfont.woff"; //iconfont.woff

//引入ElementUI组件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

//引入全局空视图组件
import EmptyView from "./components/EmptyView.vue";
Vue.component("EmptyView", EmptyView);

//引入网络请求
import http from "./utils/http.js";
Vue.prototype.$http = http;

//引入Api
import Api from "./api/Api.js";
Vue.prototype.$request = Api;

//引入全局定义的Key文件
import key from "./utils/Key";
Vue.prototype.$key = key;

//引入公共工具类
import commonUtils from "./utils/CommonUtils";
Vue.use(commonUtils);
import code from "./utils/Code";
Vue.use(code);

//引入轮播图 注意这里swiper的版本指定 npm install  swiper@5.x vue-awesome-swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

//引入Vue查看大图(vue-photo-preview) 用法:在img标签添加preview属性 preview值相同即表示为同一组
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
Vue.use(preview);

//引入全局定义的项目配置的网络请求地址
import HttpAddress from "./utils/HttpAddress";
Vue.prototype.$api = HttpAddress;

//引入Loading的全局组件
import Loading from "./components/Loading.vue";
Vue.component("Loading", Loading);

//引入全局上传UpLoadingView组件
import UpLoadingView from "./components/UpLoadingView.vue";
Vue.component("UpLoadingView", UpLoadingView);

//引入全局组件
import Layout from "./components/Layout.vue";
Vue.component("Layout", Layout);

//申明选择级别的全局组件
import ChooseRange from "./components/ChooseRange.vue";
Vue.component("ChooseRange", ChooseRange);

//引入全局顶部标题栏组件
import TopLeftTitle from "./components/title/TopLeftTitle.vue";
Vue.component("TopLeftTitle", TopLeftTitle);

//引入全局公共Dialog
import CommonDialog from "@/components/dialog/CommonDialog.vue";
Vue.component("CommonDialog", CommonDialog);

//引入全局三级时间选择器
import TimePicker from "@/components/pick/TimePicker.vue";
Vue.component("TimePicker", TimePicker);

//引入全局的格子组件
import MultGridView from "@/components/grid/MultGridView.vue";
Vue.component("MultGridView", MultGridView);

//引入全局顶部的导航栏
import TopNavBar from "@/components/nav/TopNavBar.vue";
Vue.component("TopNavBar", TopNavBar);

//引入表格导出JS文件
import Blob from "./Excel/Blob";
import Export2Excel from "./Excel/Export2Excel.js";

//粒子效果
import VueParticles from "vue-particles";
Vue.use(VueParticles);

//引入Vue-bus
import VueBus from "vue-bus";
Vue.use(VueBus);

//引入vue-video-player
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
window.videojs = VideoPlayer.videojs; //不设置这个，引用语言会报错
require("video.js/dist/lang/zh-CN.js"); //必须引用，后面设置语言才会生效
require("./assets/css/video.css");
Vue.use(VideoPlayer);

// import echarts from 'echarts'
//   Vue.prototype.$echarts = echarts

let vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
