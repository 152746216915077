//三级时间选择器
<template>
    <div class="time_picker">
        <!-- 年 -->
        <el-select v-model="yearValue" clearable placeholder="选择年" @change="changeYear">
            <el-option v-for="item in yearArr" :key="item" :label="item" :value="item">
            </el-option>
        </el-select>
        <!-- 月 -->
        <el-select v-model="monthValue" clearable placeholder="选择月" @change="changeMonth">
            <el-option v-for="item in monthArr" :key="item" :label="item" :value="item">
            </el-option>
        </el-select>
        <!-- 日 -->
        <el-select v-model="dayValue" clearable placeholder="选择日" v-if="isShowDay">
            <el-option v-for="item in dayArr" :key="item" :label="item" :value="item">
            </el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //年的数据
            yearArr: [],
            //月的数据
            monthArr: [],
            //天的数据
            dayArr: [],
            //选择的年
            yearValue: "",
            //选择的月
            monthValue: "",
            //选择的日
            dayValue: "",
        };
    },
    created() {
        this.initData();
    },
    methods: {
        //初始化年月日的数据
        initData() {
            //获取当前日期的年
            let date = new Date();
            let y = date.getFullYear();
            //年份默认为当前年份往前15年
            for (let i = 15; i >= 0; i--) {
                this.yearArr.push(y - i);
            }
            // console.log("创建好的年份数据是:", this.yearArr);
        },

        //选中年
        changeYear() {
            this.sysMonthArr();
            //同步月的数据
            this.sysDayArr();
        },

        //选中月
        changeMonth() {
            this.sysDayArr();
        },

        //同步月份的方法
        sysMonthArr() {
            if (!this.isEmpty(this.yearValue)) {
                //先清空月的数据在添加
                this.monthArr.splice(0, this.monthArr.length);
                this.monthArr = this.monthArr.concat([
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                ]);
            }
        },

        //同步天的方法
        sysDayArr() {
            if (
                !this.isEmpty(this.yearValue) &&
                !this.isEmpty(this.monthValue)
            ) {
                let days = this.getDaysInMonth(this.yearValue, this.monthValue);
                //先清空数据
                this.dayValue = "";
                this.dayArr.splice(0, this.dayArr.length);
                //创建天的数据
                for (let i = 1; i <= days; i++) {
                    if (i < 10) {
                        this.dayArr.push("0" + i);
                    } else {
                        this.dayArr.push(i);
                    }
                }

                // console.log("创建好的天数是:", this.dayArr);
            }
        },
    },
    watch: {
        // 监听选择的年和月的数据
        yearValue(newValue, oldValue) {
            if (this.isEmpty(newValue)) {
                this.monthValue = "";
                this.monthArr.splice(0, this.monthArr.length);
                this.dayValue = "";
                this.dayArr.splice(0, this.dayArr.length);
            }
        },

        monthValue(newValue, oldValue) {
            if (this.isEmpty(newValue)) {
                this.dayValue = "";
                this.dayArr.splice(0, this.dayArr.length);
            }
        },
    },
    computed: {},
    components: {},
    props: {
        //是否显示日的选项
        isShowDay: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
.time_picker {
    ::v-deep .el-input {
        width: 80px !important;
    }
}
</style>