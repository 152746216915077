//通用的弹框组件
<template>
  <el-dialog
    :center="isCenter"
    :title="title"
    :visible.sync="showDialog"
    :width="width"
    :close-on-click-modal="closeClick"
    :before-close="handleClose"
  >
    <!-- 插槽部分 -->
    <slot name="content"></slot>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    close() {
      this.$emit("close");
    },

    handleClose(done) {
      this.close();
    },
  },
  computed: {},
  components: {},
  props: ["showDialog", "title", "width", "isCenter","closeClick"],
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding-bottom: 10px;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::v-deep .el-icon-close:before {
  cursor: pointer;
}
</style>
