//左侧菜单栏的选中值    采用模块化的处理方式 以下的访问方式都是基于模块化的访问
//this.$store.state.模块名称.属性名
const state = {
  //默认选中的索引值
  defaulActivedIndex: "",
}

// 获取方式this.$store.getters["模块名/方法名"]
const getters = {
  getDefaulActivedIndex() {
    return state.defaulActivedIndex
  }
}

//this.$store.commit("模块名/方法名",参数);
const mutations = {
  setDefaulActivedIndex(state, activedIndex) {

    console.log('触发这里了', activedIndex)
    state.defaulActivedIndex = activedIndex

    console.log('更改后的值是', state.defaulActivedIndex)
  }
}

// this.$store.dispatch("模块名/方法名",参数);
const acitons = {
  setDefaulActivedIndex(context, activedIndex) {
    context.commit('setDefaulActivedIndex', activedIndex)
  }
}

export default {
  //用于在全局引用此文件里的方法时标识这一个的文件名,解决命名冲突问题
  namespaced: true,
  state,
  getters,
  mutations,
  acitons
}
