<template>
  <div class="top_nav_bar flex_h">
    <div
      @click.stop="onItemClick(index)"
      :class="['item_nav', { actived: curPos == index }]"
      v-for="(item, index) in tabArr"
      :key="index"
    >
      {{ item }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //当前选中的位置
      curPos: 0,
      //导航数据
      // tabArr: ["基本信息", "课表及考勤"],
    };
  },
  created() {},
  methods: {
    //条目点击事件
    onItemClick(pos) {
      if (this.curPos != pos) {
        this.curPos = pos;
        this.$emit("onItemClick", pos);
      }
    },
  },
  computed: {},
  components: {},
  props: ["tabArr"],
};
</script>
<style lang="scss" scoped>
.top_nav_bar {
  justify-content: start;
  flex-wrap: wrap;
  height: 50px;
  .item_nav {
    margin-right: 40px;
    cursor: pointer;
  }
  .actived {
    color: #2aa4d9;
  }
}
</style>