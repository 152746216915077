<template>
    <div class="empty_view">
        <el-empty description="暂无数据"></el-empty>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {},
    components: {},
};
</script>
<style lang='scss' scoped>
.empty_view {
    position: absolute;
    height: 100%;
    width: 100%;

    .el-empty {
        height: 100%;
    }
}
</style>