// 封装的Http请求
import axios from "axios";
import qs from "qs";

axios.defaults.timeout = 25000; // 响应时间
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8"; // 配置常规请求头
axios.defaults.baseURL = ""; // 配置接口地

//定义formData的请求键
const key = "Content-Type";
// 表单形式
let config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做某件事
    // && config.headers.Content - Type != "multipart/form-data"
    if (config.method === "post" && config.headers[key] != "multipart/form-data") {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    console.log("错误的传参");
    return Promise.reject(error);
  }
);

// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    // console.log('响应的数据是:', res)
    // 对响应数据做些事
    if (!res.data.success) {
      return Promise.resolve(res);
    }
    return res;
  },
  (error) => {
    console.log("网络异常");
    return Promise.reject(error);
  }
);

export function fetchFormDataPost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

// 返回一个Promise(发送post请求)
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

//返回一个Promise(发送get请求)
export function fetchGet(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

// 返回一个配置全局参数的方法
export function setToken(token) {
  axios.defaults.headers.common["token"] = token;
}
export default {
  fetchPost,
  fetchGet,
  axios,
  setToken,
  fetchFormDataPost,
};
