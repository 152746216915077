// 顶部的左边的标题栏导航方式,采用插槽方式
<template>
    <div class="top_left_title">
        <!-- 使用具名插槽的方式来动态配置顶部的标题展示 -->
        <slot name="top_title"></slot>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.top_left_title {
    height: 77px;
    position: absolute;
    display: flex;
    margin: 0 14px;
    align-items: center;
    top: 0;
    left: 0;
}
</style>