<!-- 通用的页面布局组件 -->
<template>
    <div class="layout">
        <!-- 内容部分 -->
        <div class="layout_content">
            <!-- 加载布局 -->
            <div class="loading_view" v-if="showLoading == -1">
                <Loading></Loading>
            </div>
            <!-- 内容布局 -->
            <div class="content_view" v-if="showLoading == 1">
                <!-- 采用插槽的方式让内容布局填充 -->
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
// import LoadingView from "./Loading.vue";
export default {
    data() {
        return {};
    },
    components: {
        // LoadingView,
    },
    props: ["showLoading"],
};
</script>

<style lang="scss" scoped>
.layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .layout_content {
        flex: 1;
        height: 100%;
        .loading_view {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .content_view {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
