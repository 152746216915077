//项目中通用的封装的工具方法
exports.install = function (Vue, options) {
  //定义全部Dialog的宽度变量
  Vue.prototype.dialogWidth = "330px";

  // 注意一个规则:需要在函数内部使用this就不要使用箭头函数申明
  // 统一弹出警告吐司的方法
  Vue.prototype.showWarnMsg = (msg) => {
    Vue.prototype.$message({
      message: msg,
      type: "warning",
      // center: true,
      duration: 2000,
    });
  };

  // 统一弹出成功吐司的方法
  Vue.prototype.showSuccessMsg = (msg) => {
    Vue.prototype.$message({
      message: msg,
      type: "success",
      // center: true,
      duration: 2000,
    });
  };

  // 统一弹出错误吐司的方法
  Vue.prototype.showErrorMsg = (msg) => {
    Vue.prototype.$message({
      message: msg,
      type: "error",
      // center: true,
      duration: 2000,
    });
  };

  // 统一弹出Toast方法
  Vue.prototype.notifyToast = (msg, type, title) => {
    Vue.prototype.$notify({
      title: title,
      message: msg,
      type: type,
      dangerouslyUseHTMLString: true,
      duration: 2000,
      offset: 30,
    });
  };

  // 判断对象是否为空的方法
  Vue.prototype.isEmpty = (obj) => {
    if (obj == "0") {
      return false;
    } else if (
      typeof obj == "undefined" ||
      obj == null ||
      obj == "" ||
      obj == []
    ) {
      return true;
    } else {
      return false;
    }
  };

  //封装公共请求参数的方法
  Vue.prototype.getHttpParams = () => {
    //取出登录信息
    let useInfo = JSON.parse(localStorage.getItem("userInfo"));
    let obj = new Object();
    obj.uid = useInfo.id;
    obj.role = useInfo.role;
    obj.user_id = useInfo.id;
    obj.userId = useInfo.id;
    obj.token = useInfo.token;
    if (useInfo.schoolId) {
      obj.school_id = useInfo.schoolId;
      obj.schoolId = useInfo.schoolId;
    }
    return obj;
  };

  //获取登录的用户信息
  Vue.prototype.getUserInfo = () => {
    return JSON.parse(localStorage.getItem("userInfo"));
  };

  //获取用户角色 -1游客 0学生  1老师 2校区教务 3校长 4管理员 5超级管理员
  Vue.prototype.getUserRole = () => {
    var useInfo = JSON.parse(localStorage.getItem("userInfo"));
    return useInfo.role;
  };

  //判断是否连续点击的方法
  Vue.prototype.isQuickClick = function () {
    var res = false;

    //当前点击的时间
    var currentTime = new Date().getTime();

    //获取用户上次点击的时间
    var lastTime = this.$store.getters.getLastTime;

    //获取点击的时间差
    var deffTime = currentTime - lastTime;

    if (deffTime > 1000) {
      //判断时间间隔差大于1秒,认为不是快速点击
      res = false;
    } else {
      res = true;
    }

    //重新记录用户上次点击的时间
    this.$store.dispatch("actLastTime", currentTime);

    return res;
  };

  //判断用户是否登录的方法
  Vue.prototype.hasLogin = function () {
    var useInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (this.isEmpty(useInfo)) {
      return false;
    } else {
      return true;
    }
  };

  //清空localStorage的数据
  Vue.prototype.clearStorage = function () {
    localStorage.clear();
  };

  //时间
  Vue.prototype.timestampToTime = function (timestamp, endTime, splitType) {
    if (!this.isEmpty(splitType)) {
      splitType = "-";
    }
    var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + splitType;
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + splitType;
    var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    var h =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    if (this.isEmpty(endTime) && endTime == "D") {
      return Y + M + D;
    } else {
      return Y + M + D + h + m;
    }
  };

  //制保留2位小数，如：2，会在2后面补上00.即2.00
  (Vue.prototype.toDecimal = (x) => {
    var f = parseFloat(x);
    if (isNaN(f)) {
      return false;
    }
    var f = Math.round(x * 100) / 100;
    var s = f.toString();
    var rs = s.indexOf(".");
    if (rs < 0) {
      rs = s.length;
      s += ".";
    }
    while (s.length <= rs + 2) {
      s += "0";
    }
    return s;
  }),
    /**
     * 防抖函数的使用,解决问题的场景:比如用户输入搜索的功能,使用该函数可以实现在默认世间内用户如果连续输入就不执行操作,超过默认事件用户
     * 没有继续输入则认为用户输入结束,然后执行相关的业务逻辑
     */
    (Vue.prototype.dobounce = (fun, delay) => {
      let timer = null;
      return function (...args) {
        //先判断定时器是否为空,不为空则取消定时器
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          fun.apply(this, args);
        }, delay);
      };
    });

  // 日期格式化工具类
  /**
   * 将日期格式化成指定格式的字符串
   * @param date 要格式化的时间戳，不传时默认当前时间，也可以是一个时间戳
   * @param fmt 目标字符串格式，支持的字符有：y,M,d,q,w,H,h,m,S，默认：yyyy-MM-dd HH:mm:ss
   * @returns 返回格式化后的日期字符串
   */
  Vue.prototype.formatDate = (date, fmt) => {
    date = date == undefined ? new Date() : date;
    date = typeof date == "number" ? new Date(date) : date;
    fmt = fmt || "yyyy-MM-dd HH:mm:ss";
    var obj = {
      y: date.getFullYear(), // 年份，注意必须用getFullYear
      M: date.getMonth() + 1, // 月份，注意是从0-11
      d: date.getDate(), // 日期
      q: Math.floor((date.getMonth() + 3) / 3), // 季度
      w: date.getDay(), // 星期，注意是0-6
      H: date.getHours(), // 24小时制
      h: date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, // 12小时制
      m: date.getMinutes(), // 分钟
      s: date.getSeconds(), // 秒
      S: date.getMilliseconds(), // 毫秒
    };
    var week = ["天", "一", "二", "三", "四", "五", "六"];
    for (var i in obj) {
      fmt = fmt.replace(new RegExp(i + "+", "g"), function (m) {
        var val = obj[i] + "";
        if (i == "w") return (m.length > 2 ? "星期" : "周") + week[val];
        for (var j = 0, len = val.length; j < m.length - len; j++)
          val = "0" + val;
        return m.length == 1 ? val : val.substring(val.length - m.length);
      });
    }
    return fmt;
  };

  // 根据指定字符串获取时间戳的方法
  Vue.prototype.getTimestamp = (timeStr) => {
    var res = Date.parse(new Date(timeStr));
    return res;
  };

  // 判断手机号是否合法的方法
  Vue.prototype.testPhone = (phoneNum) => {
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phoneNum)) {
      return false;
    } else {
      return true;
    }
  };
  //格式化秒数到时间格式
  Vue.prototype.formatSeconds = (value) => {
    var res = "";

    var theTime = parseInt(value); // 秒

    var theTime1 = 0; // 分

    var theTime2 = 0; // 小时

    //如果描述大于60秒
    if (theTime > 60) {
      //计算分钟
      theTime1 = parseInt(theTime / 60);

      //计算描述
      theTime = parseInt(theTime % 60);

      if (theTime1 > 60) {
        //分钟大于60分钟,计算小时
        theTime2 = parseInt(theTime1 / 60);

        theTime1 = parseInt(theTime1 % 60);

        //计算小时的格式
        if (theTime2.toString().length == 1) {
          theTime2 = "0" + theTime2;
        }
        //计算分钟的格式
        if (theTime1.toString().length == 1) {
          //分钟显示1位数
          theTime1 = "0" + theTime1;
        }

        //计算秒的格式
        if (theTime.toString().length == 1) {
          theTime = "0" + theTime;
        }
        res = theTime2 + ":" + theTime1 + ":" + theTime;
      } else {
        //小于一个小时
        //计算秒上显示的格式
        if (theTime.toString().length == 1) {
          theTime = "0" + theTime;
        }
        //计算分钟上显示的格式
        //小于60分钟
        if (theTime1.toString().length == 1) {
          //分钟显示1位数
          theTime1 = "0" + theTime1;
        }
        res = theTime1 + ":" + theTime;
      }
    } else {
      //小于60秒
      if (theTime.toString().length == 1) {
        //小于10秒
        res = "00:0" + theTime;
      } else {
        //大于10秒
        res = "00:" + theTime;
      }
    }
    return res;
  };

  /**
   * 获取链接后面参数
   * @param   {String}  url  链接地址
   * @param   {Number}  type 获取值类型 1 返回数组，可获取每个参数 2 获取？后所有参数及参数值
   * @return  {object}
   * @return  {string}
   */
  Vue.prototype.GetRequest = (url, type) => {
    var theRequest;
    switch (type) {
      case 1:
        theRequest = new Object();
        strs = url.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
        break;
      case 2:
        theRequest = "";
        var num = url.indexOf("?");
        theRequest = url.substr(num + 1);
        break;
      default:
        break;
    }
    return theRequest;
  };

  /**
   * 将秒转化为00:00:00的格式
   */
  Vue.prototype.forMatterTime = (value) => {
    let res = "";
    if (value < 60) {
      if (value < 10) {
        //小于10秒
        res = "00:0" + value;
      } else {
        //小于60秒
        res = "00:" + value;
      }
    } else if (value < 60 * 60) {
      //小于1小时
      //计算分钟
      // let m = "" + parseInt(value / 60);

      // let m =
      //   "" + parseInt(((value * 1000) % (1000 * 60 * 60)) / (1000 * 60));
      let m = "" + Math.floor((value / 60) % 60);

      //计算秒
      // let s = "" + ((value * 1000) % (1000 * 60)) / 1000;
      let s = "" + Math.floor(value % 60);

      if (m.length < 2) {
        m = "0" + m;
      }

      if (s.length < 2) {
        s = "0" + s;
      }
      res = m + ":" + s;
    } else {
      //大于1小时
      //计算小时
      // let h =
      //   "" +
      //   parseInt(((value * 1000) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let h = "" + Math.floor(value / 3600);

      //计算分钟
      // let m =
      //   "" + parseInt(((value * 1000) % (1000 * 60 * 60)) / (1000 * 60));
      let m = "" + Math.floor((value / 60) % 60);

      //计算秒
      // let s = "" + (value % 60);
      // let s = "" + ((value * 1000) % (1000 * 60)) / 1000;
      let s = "" + Math.floor(value % 60);

      if (h.length < 2) {
        h = "0" + h;
      }

      if (m.length < 2) {
        m = "0" + m;
      }

      if (s.length < 2) {
        s = "0" + s;
      }
      res = h + ":" + m + ":" + s;
    }
    return res;
  };

  /**
   * 获取指定元素在数组中的位置
   * @param {*} val 指定元素
   * @param {*} arr 数组
   * @returns
   */
  Vue.prototype.indexOf = function (val, arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == val) return i;
    }
    return -1;
  };

  /**
   * 删除数组中的指定的元素
   * @param {*} val 要删除的元素
   * @param {*} arr 数组
   */
  Vue.prototype.remove = function (val, arr) {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i] == val) {
        arr.splice(i, 1);
      }
    }
  };

  /**
   * 判断字符串为纯空格
   * @param {*} str 需要比对的字符串
   */
  Vue.prototype.isAllSpace = function (str) {
    return str.match(/^[ ]*$/);
  };

  /**
   * 验证是否是纯数字
   * @param {*} dateTimeStamp
   * @returns
   */
  Vue.prototype.isNumber = function (inputValue) {
    //汉字、数字、字母、下划线，下划线位置不限
    let reg = /^[0-9]*$/;
    return reg.test(inputValue);
  };

  /**
   * 不能包含数字和特殊字符
   * @param {} str
   * @returns
   */
  Vue.prototype.regExp = function (str) {
    // 规则对象(flag)
    let flag = new RegExp(
        "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      ),
      num = /[0-9]/;
    // 判断 even 是否包含特殊字符
    if (flag.test(str) || num.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 时间转化为几天前,几小时前，几分钟前
   * @param {*} dateTimeStamp 服务器返回的时间戳
   * @returns
   */
  Vue.prototype.getDateDiff = function (dateTimeStamp) {
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var halfamonth = day * 15;
    var month = day * 30;
    var now = new Date().getTime();
    var diffValue = now - dateTimeStamp;
    if (diffValue < 0) {
      return;
    }
    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    if (monthC >= 1) {
      result = "" + parseInt(monthC) + "月前";
    } else if (weekC >= 1) {
      result = "" + parseInt(weekC) + "周前";
    } else if (dayC >= 1) {
      result = "" + parseInt(dayC) + "天前";
    } else if (hourC >= 1) {
      result = "" + parseInt(hourC) + "小时前";
    } else if (minC >= 1) {
      result = "" + parseInt(minC) + "分钟前";
    } else result = "刚刚";
    return result;
  };

  /**
   * 获取某年某月一共多少天
   * @param {*} year
   * @param {*} month
   * @returns
   * 使用   getDaysInMonth(2018,02)
   */
  Vue.prototype.getDaysInMonth = (year, month) => {
    let date = new Date(year, month, 1);
    return new Date(date.getTime() - 864e5).getDate();
  };

  /**
   * 获取
   * @param {} date 指定日期
   * @returns
   */
  Vue.prototype.getWeek = (date) => {
    // let weekArray = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六")
    let dayIndex = new Date(date).getDay();
    //返回星期几
    // return weekArray[dayIndex]
    //返回索引
    return dayIndex;
  };

  /**
   * 数组按照指定的某个属性排序  使用方法 arr.sort(this.sortArr('参数名'))
   * @param {*} attr
   * @returns
   */
  Vue.prototype.sortArr = (attr) => {
    return function (a, b) {
      return a[attr] - b[attr];
    };
  };

  /**
   * 统一获取表格宽度的方法
   */
  Vue.prototype.getTabWidth = () => {
    return window.screen.width - 230;
  };

  /**
   * 判断2个时间端是否大于1天
   */
  Vue.prototype.isRightTimeRange = (beginDate, endDate) => {
    let begin = new Date(beginDate).getTime();
    let end = new Date(endDate).getTime();
    //这里不乘1000是为了给一定间距
    let day = (end - begin) / (24 * 60 * 60 * 800);
    // && day <= 365
    if (day > 1) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 判断2个时间端是间隔一年
   */
  Vue.prototype.isOverTimeRange = (beginDate, endDate) => {
    let begin = new Date(beginDate).getTime();
    let end = new Date(endDate).getTime();
    let subTime = end - begin;
    let yearTime = 24 * 60 * 60 * 1000 * 365;
    if (subTime > yearTime) {
      return true;
    } else {
      return false;
    }
  };

  //6-20 必须包含数字加字母 不能包含特殊符号
  Vue.prototype.testPwd = (str) => {
    // if (str == null || str.length < 6 || str.length > 20) {
    //   return false;
    // }
    // var reg1 = new RegExp(/^[0-9A-Za-z]+$/);
    // if (!reg1.test(str)) {
    //   return false;
    // }
    // var reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
    // if (reg.test(str)) {
    //   return true;
    // } else {
    //   return false;
    // }

    //特殊字符的验证
    var p = new RegExp(
      "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]"
    );
    //6到20位纯数字,字母或者数字+字母并且不能包含特殊字符
    var regu = "^[0-9a-zA-Z]{6,20}$";
    var re = new RegExp(regu);

    if (re.test(str) && !p.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 验证字符串只能是汉字和英文
   */
  Vue.prototype.validateRule = function (str) {
    //汉字、数字、字母、下划线，下划线位置不限
    let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
    return reg.test(str);
  };

  /**
   * 验证是否为空
   * @param {*} value
   */
  Vue.prototype.isDefine = (value) => {
    if (
      value == null ||
      value == "" ||
      value == "undefined" ||
      value == undefined ||
      value == "null" ||
      value == "(null)" ||
      value == "NULL" ||
      typeof value == "undefined"
    ) {
      return false;
    } else {
      value = value + "";
      value = value.replace(/\s/g, "");
      if (value == "") {
        return false;
      }
      return true;
    }
  };

  // /**
  //  * 防抖函数 只有在某个时间内，没有再次触发某个函数时，才真正的调用这个函数
  //  * @param {*} fn 外部传入的函数
  //  * @param {*} delay 延迟的时间
  //  * @param {*} immediate 是否立即执行
  //  */
  // Vue.prototype.debounce = (fn, delay, immediate = false) => {
  //   // 定义一个定时器， 保存上一次的定时器
  //   let timer = null;
  //   // 是否立即执行
  //   let isInvoke = false;

  //   // 真正执行的函数
  //   const _debounce = function (...args) {
  //     // 取消上一次的定时器
  //     if (timer) clearTimeout(timer);
  //     // 判断是否需要立即执行
  //     if (immediate && !isInvoke) {
  //       // 立即执行
  //       fn.apply(this, args);
  //       isInvoke = true;
  //     } else {
  //       // 延迟执行
  //       timer = setTimeout(() => {
  //         // 外部传入的真正要执行的函数 矫正this的指向
  //         fn.apply(this, args);
  //         isInvoke = false;
  //         timer = null;
  //       }, delay);
  //     }
  //   };
  //   // 封装取消功能
  //   _debounce.cancel = function () {
  //     if (timer) clearTimeout(timer);
  //     timer = null;
  //     isInvoke = false;
  //   };
  //   return _debounce;
  // };

  // /**
  //  * 节流函数 规定在一个单位时间内，只能触发一次函数。如果这个单位时间内触发多次函数，只有一次生效
  //  * @param {} fn 外部传入的函数
  //  * @param {*} interval 延迟的时间
  //  * @param {*} options
  //  * @returns
  //  */
  // Vue.prototype.throttle = (
  //   fn,
  //   interval = 2000,
  //   options = { leading: true, trailing: false }
  // ) => {
  //   const { leading, trailing } = options;
  //   // 记录上一次的开始的时间
  //   let lastTime = 0;
  //   let timer = null;
  //   // 时间触发时，真正执行的函数
  //   const _throttle = function () {
  //     // 获取当前事件触发时的时间
  //     const nowTime = new Date().getTime();
  //     // 当最后的时候和当前时间相等的时候(第一次没有触发)
  //     if (!lastTime && !leading) lastTime = nowTime;
  //     // 使用当前触发的时间和之前的时间间隔以及上一次开始的时间， 计算出还剩余多长事件需要去触发函数
  //     const remainTime = interval - (nowTime - lastTime);
  //     if (remainTime <= 0) {
  //       if (timer) {
  //         clearTimeout(timer);
  //         timer = null;
  //       }
  //       // 真正触发函数
  //       fn();
  //       // 保留上次触发的时间
  //       lastTime = nowTime;
  //       // 没有加定时器的时候直接return掉
  //       return;
  //     }
  //     // 只需要有一个定时器即可
  //     if (trailing && !timer) {
  //       timer = setTimeout(() => {
  //         timer = null;
  //         // 仅仅执行一次
  //         lastTime = !leading ? 0 : new Date().getTime();
  //         fn();
  //       }, remainTime);
  //     }
  //   };
  //   return _throttle;
  // };
};
