//选择范围的组件
<template>
    <div class="choose_range">
        <div class="top_info">

            <span>试用范围：</span>

            <div :class="['right_drop']">

                <!-- 回显选中的节点信息div -->
                <div :class="['show_choose_content',{'sty_dis':!disableAction}]" @click.stop="doActionDropView">
                    <!-- :title="showResValue" #C0C4CC -->
                    <span class="show_res_value">{{showResValue}}</span>
                </div>

                <!-- 下拉框 -->
                <el-collapse-transition>

                    <el-card :class="['drop_view',isShowDropView ? 't_down':'t_up']" v-show="isShowDropView">

                        <!-- 树形选择 需要加入选中操作时加入右边代码 show-checkbox @check-change="handleCheckChange" -->
                        <el-tree node-key="id" ref="tree" :props="props" :data="chooseData">
                        </el-tree>

                        <!-- 取消和确定按钮 -->
                        <!-- <div class="bom_bt_action"> -->

                        <!-- 取消 -->
                        <!-- <div class="com_bt_action bt_cancel" @click.stop="doCancel">取消</div> -->

                        <!-- 下一步 -->
                        <!-- <el-button class="com_bt_action bt_next" @click.stop="doCommit">确定</el-button> -->
                        <!-- </div> -->

                    </el-card>

                </el-collapse-transition>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //是否显示下拉框的标识
            isShowDropView: false,

            // //数据源
            // chooseData: [
            //     {
            //         label: "第一级别",
            //         id: "1001",
            //         children: [
            //             {
            //                 id: "2001",
            //                 label: "第1单元",
            //             },
            //             {
            //                 id: "2002",
            //                 label: "第2单元",
            //             },
            //         ],
            //     },
            //     {
            //         label: "第二级别",
            //         id: "1002",
            //         children: [
            //             {
            //                 id: "2003",
            //                 label: "第1单元",
            //             },
            //             {
            //                 id: "2004",
            //                 label: "第2单元",
            //             },
            //         ],
            //     },
            // ],

            props: {
                children: "children",
                label: "label",
            },

            //操作tree后需要回显的数据
            showResArr: [],

            //回显的值
            showResValue: "点击查看试用范围",
        };
    },
    created() {},
    methods: {
        //显示下拉框的方法
        doActionDropView() {
            //只有是允许操作的状态才显示下拉框
            if (this.disableAction) {
                this.isShowDropView = !this.isShowDropView;
            }
        },

        //节点选中状态发生变化时的回调 共三个参数，依次为：传递给 data 属性的数组中该节点所对应的对象、节点本身是否被选中、节点的子树中是否有被选中的节点
        handleCheckChange(data, checked, indeterminate) {
            // console.log(data, checked, indeterminate);
        },

        //取消
        doCancel() {
            //清空选中的值
            // this.$refs.tree.setCheckedKeys([]);
            if (this.isShowDropView) {
                this.isShowDropView = false;
            }
        },

        //确定
        doCommit() {
            //获取选中的值并且进行回显
            //返回目前被选中的节点的 key 所组成的数组
            let res = this.$refs.tree.getCheckedKeys();
            // console.log("获取被选中的节点的数组是:", res);

            //根据选中节点的key值来组装回显的数据
            this.getShowResByCheckedKeys(res);

            this.isShowDropView = false;

            // console.log("回显数据是:", this.showResArr);
        },

        //根据选中节点的key值来组装回显的数据
        getShowResByCheckedKeys(checkedKeysArr) {
            this.showResArr.splice(0, this.showResArr.length);
            for (let i = 0; i < this.chooseData.length; i++) {
                let parent = this.chooseData[i];
                //取出孩子数据
                let children = parent.children;
                //遍历孩子数据
                for (let j = 0; j < children.length; j++) {
                    let item = children[j];
                    checkedKeysArr.forEach((el) => {
                        if (el == item.id) {
                            //表示改节点被选中了,那么就创建一个回显对象数据
                            this.showResArr.push({
                                parentId: parent.id,
                                childrenId: item.id,
                                lable: `${parent.label}${item.label}`,
                            });
                        }
                    });
                }
            }

            let labelsArr = this.showResArr.map((item) => {
                return item.lable;
            });

            this.showResValue = labelsArr.join(",");
        },

        //点击回显列表的条目关闭按钮事件
        doCloseRes(item) {},
    },
    computed: {},
    components: {},
    props: ["disableAction", "chooseData"],
};
</script>
<style lang="scss" scoped>
$drop_view_width: 220px;
.choose_range {
    z-index: 999;
    .top_info {
        display: flex;
        align-items: center;
        .right_drop {
            display: flex;
            flex-direction: column;
            position: relative;
            .show_choose_content {
                width: $drop_view_width;
                height: 26px;
                border: 1px solid #dcdfe6;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                align-items: center;
                // white-space: nowrap; //让外层盒子不换行

                .show_res_value {
                    width: 100%;
                    margin: 0 10px;
                    font-size: $tv_sm_size;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #c0c4cc;
                }

                .el-scrollbar {
                    height: 100%;
                }

                ::v-deep .el-scrollbar__view {
                    margin-top: 4px;
                }
                ::v-deep .el-scrollbar__wrap {
                    height: 100%;
                    overflow-x: hidden;
                }

                ::v-deep .el-scrollbar__bar.is-horizontal {
                    height: 4px;
                }

                ::v-deep .el-scrollbar__bar {
                    bottom: 0;
                }

                .item_show_res {
                    height: 20px;
                    font-size: 12px;
                    background: #169bd5;
                    padding: 0px 4px;
                    border-radius: 2px;
                    margin: 0px 4px;
                    display: inline-block;

                    .el-icon-close {
                        cursor: pointer;
                        color: white;
                        margin-left: 4px;
                    }
                }
            }

            .sty_dis {
                background-color: #f5f7fa;
                border-color: #e4e7ed;
            }

            .drop_view {
                top: 32px;
                left: 0;
                position: absolute;
                width: $drop_view_width;
                display: flex;
                flex-direction: column;

                ::v-deep .el-card__body {
                    padding: 14px 10px 14px;
                }

                //底部的取消和确定按钮
                .bom_bt_action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 16px;

                    .bt_cancel,
                    .bt_next {
                        width: 80px;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: $tv_sm_size;
                        height: 28px;
                    }

                    .bt_cancel {
                        background: #d5d6d7;
                    }

                    .bt_next {
                        margin-left: 30px;
                        height: 30px;
                        background: #409eff;
                    }
                }
            }
        }
    }
}
</style>