//项目打包的配置文件
const APP_FLAG = "智多蛙";
// const APP_FLAG = "纳深";
const AppName = APP_FLAG === "智多蛙" ? "智多蛙后台管理系统" : "纳深机器人后台管理系统";
const AppLog = APP_FLAG === "智多蛙" ? require("../assets/img/logo.png") : require("../assets/img/ns_logo.png");
const AppIcon = APP_FLAG === "智多蛙" ? "favicon.ico" : "ns_favicon.ico";

export default {
  AppName,
  AppLog,
  AppIcon,
};
