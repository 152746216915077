//公共请求的APi
import httpAddrss from "../utils/HttpAddress.js";
import http from "../utils/http.js";

/**
 * 判定是否为空的方法
 */
const isEmpty = (obj) => {
  if (obj == "0") {
    return false;
  } else if (typeof obj == "undefined" || obj == null || obj == "" || obj == []) {
    return true;
  } else {
    return false;
  }
};

/**
 * 获取公共请求参数
 */
const getCommonParam = () => {
  let obj = new Object();
  let useInfo = JSON.parse(localStorage.getItem("userInfo"));
  obj.uid = useInfo.id;
  obj.token = useInfo.token;
  return obj;
};

/**
 * 获取学校加盟的课程
 */
const getSchoolLesson = (param) => {
  return http.fetchPost(httpAddrss.ALLLESSONS, param);
};

/**
 * 获取教师列表
 * type 1  固定参数
 * uid  用户id
 * token
 */
export const getTeacherList = () => {
  let param = getCommonParam();
  param.type = 1;
  return http.fetchGet(httpAddrss.DATA_CENTER, param);
};

/**
 * 获取教师数据汇总表
 * tid 教师id（指定教师时用）
 * startDate 开始时间（不填默认当前月）
 * endDate 结束时间（不填默认 = startDate） 不传
 * orderField 排序字段(对应字段名）：attendance_percent 实际出勤率；class_elimination_real 当月实际课消数；new_add_number 新增正式生人数；old_add_number 续费人数
 * orderType 排序规则 ASC：升序   ESC或其他：降序
 * pageIndex 页码
 * pageNum 每页条数
 */
export const getTeacherTableData = (tid, startDate, orderField, orderType, pageIndex, pageNum) => {
  let param = getCommonParam();
  param.type = 2;
  if (!isEmpty(tid)) {
    param.tid = tid;
  }
  if (!isEmpty(startDate)) {
    param.startDate = startDate;
  }
  if (!isEmpty(orderField)) {
    param.orderField = orderField;
  }
  if (!isEmpty(orderType)) {
    param.orderType = orderType;
  }

  if (!isEmpty(pageIndex)) {
    param.pageIndex = pageIndex;
  }

  if (!isEmpty(pageNum)) {
    param.pageNum = pageNum;
  }
  return http.fetchPost(httpAddrss.DATA_CENTER, param);
};

/**
 * 获取班级数据汇总表
 * startDate 开始时间（不填默认当前月前一个月）
 */
export const getStudentData = (startDate) => {
  let param = getCommonParam();
  param.type = 3;
  if (!isEmpty(startDate)) {
    param.startDate = startDate;
  }
  return http.fetchPost(httpAddrss.DATA_CENTER, param);
};

/**
 * 获取班级数据汇总表
 * tid  教师id（指定教师时用）
 * startDate 开始时间（不填默认当前月）
 * orderField 排序字段(对应字段名）：attendance_present 实际出勤率
 * orderType 排序规则 ASC：升序   ESC或其他：降序
 */
export const getClassData = (tid, startDate, orderField, orderType) => {
  let param = getCommonParam();
  param.type = 4;
  if (!isEmpty(tid)) {
    param.tid = tid;
  }
  if (!isEmpty(startDate)) {
    param.startDate = startDate;
  }
  if (!isEmpty(orderField)) {
    param.orderField = orderField;
  }
  if (!isEmpty(orderType)) {
    param.orderType = orderType;
  }
  return http.fetchPost(httpAddrss.DATA_CENTER, param);
};

/**
 * 给学员进行续费操作
 * student_id 学生编号
 * student_subjects 学生的基本信息和班级数据（学生列表里有）
 * school_id 学校编号
 * teacher_id  老师编号
 * add_hour  增加的课时
 */
export const renewStudent = (student_id, student_subjects, school_id, teacher_id, add_hour) => {
  let param = getCommonParam();
  param.type = 19;
  param.student_id = student_id;
  param.school_id = school_id;
  param.student_subjects = student_subjects;
  param.teacher_id = teacher_id;
  param.add_hour = add_hour;
  return http.fetchPost(httpAddrss.StudentManageController, param);
};

/**
 * 标记流失
 * student_ids 学生编号（支持多个用英文逗号分隔）
 * school_id 学校编号
 * student_subjects  学生的基本信息和班级数据 对应的表格一栏的数据
 */
export const markLoss = (student_ids, school_id, student_subjects) => {
  let param = getCommonParam();
  param.type = 20;
  param.student_ids = student_ids;
  param.school_id = school_id;
  param.student_subjects = student_subjects;
  return http.fetchPost(httpAddrss.StudentManageController, param);
};
export default {
  getSchoolLesson,
};
