import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import("@/views/home/Home.vue"),
    //配置子路由
    children: [
      {
        path: "CampusManagement",
        name: "CampusManagement",
        meta: {
          title: "校区管理",
          //对应的左侧menu的选中值
          defaultIndex: "0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "校区管理",
              path: "/Home/CampusManagement",
            },
          ],
        },
        component: () => import("@/views/campusmanagement/CampusManagement.vue"),
      },
      {
        path: "ProductManagement",
        name: "ProductManagement",
        meta: {
          title: "课程管理",
          //对应的左侧menu的选中值
          defaultIndex: "1",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "课程管理",
              path: "/Home/ProductManagement",
            },
          ],
        },
        component: () => import("@/views/productmanagement/ProductManagement.vue"),
      },
      {
        path: "MyHomePage",
        name: "MyHomePage",
        meta: {
          title: "我的主页",
          //对应的左侧menu的选中值
          defaultIndex: "2-6",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "我的主页",
              path: "/Home/MyHomePage",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/homepage/MyHomePage.vue"),
      },
      {
        path: "TeacherManager",
        name: "TeacherManager",
        meta: {
          title: "教师管理",
          //对应的左侧menu的选中值
          defaultIndex: "2-0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "教师管理",
              path: "/Home/TeacherManager",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/teachermanager/TeacherManager.vue"),
      },
      {
        path: "StuManager",
        name: "StuManager",
        meta: {
          title: "学员管理",
          //对应的左侧menu的选中值
          defaultIndex: "2-1",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "学员管理",
              path: "/Home/StuManager",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/stumanager/StuManager.vue"),
      },
      {
        path: "StuRecord",
        name: "StuRecord",
        meta: {
          title: "查看学生档案",
          //对应的左侧menu的选中值
          defaultIndex: "2-1",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "学员管理",
              path: "/Home/StuManager",
            },
            {
              name: "查看学生档案",
              path: "/Home/StuRecord",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/stumanager/StuRecord.vue"),
        children: [
          {
            path: "StudentInfo",
            name: "StudentInfo",
            meta: {
              title: "基本信息",
              //对应的左侧menu的选中值
              defaultIndex: "2-1",
              //配置顶部标题栏的数据
              titleArr: [
                {
                  name: "教务中心",
                  path: "",
                },
                {
                  name: "学员管理",
                  path: "/Home/StuManager",
                },
                {
                  name: "查看学生档案",
                  path: "",
                },
              ],
            },
            component: () => import("@/views/educationalcenter/stumanager/StudentInfo.vue"),
          },
          {
            path: "StudentCourseCheck",
            name: "StudentCourseCheck",
            meta: {
              title: "课表及考勤",
              //对应的左侧menu的选中值
              defaultIndex: "2-1",
              //配置顶部标题栏的数据
              titleArr: [
                {
                  name: "教务中心",
                  path: "",
                },
                {
                  name: "学员管理",
                  path: "/Home/StuManager",
                },
                {
                  name: "查看学生档案",
                  path: "",
                },
              ],
            },
            component: () => import("@/views/educationalcenter/stumanager/StudentCourseCheck.vue"),
          },
        ],
      },
      {
        path: "ClassManager",
        name: "ClassManager",
        meta: {
          title: "班级管理",
          //对应的左侧menu的选中值
          defaultIndex: "2-2",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "班级管理",
              path: "/Home/ClassManager",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/classmanager/ClassManager.vue"),
      },
      {
        path: "CourseManager",
        name: "CourseManager",
        meta: {
          title: "排课管理",
          //对应的左侧menu的选中值
          defaultIndex: "2-3",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "排课管理",
              path: "/Home/CourseManager",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/coursemanager/CourseManager.vue"),
        children: [
          {
            path: "dayLessons",
            name: "dayLessons",
            meta: {
              title: "日课程表",
              //对应的左侧menu的选中值
              defaultIndex: "2-3",
              //配置顶部标题栏的数据
              titleArr: [
                {
                  name: "教务中心",
                  path: "",
                },
                {
                  name: "排课管理",
                  path: "/Home/CourseManager",
                },
              ],
            },
            component: () => import("@/views/educationalcenter/coursemanager/DayLessons.vue"),
          },
          {
            path: "weekLessons",
            name: "weekLessons",
            meta: {
              title: "周课表",
              //对应的左侧menu的选中值
              defaultIndex: "2-3",
              //配置顶部标题栏的数据
              titleArr: [
                {
                  name: "教务中心",
                  path: "",
                },
                {
                  name: "排课管理",
                  path: "/Home/CourseManager",
                },
              ],
            },
            component: () => import("@/views/educationalcenter/coursemanager/WeekLessons.vue"),
          },
        ],
      },
      {
        path: "editClassRoom",
        name: "editClassRoom",
        meta: {
          title: "上课教室",
          //对应的左侧menu的选中值
          defaultIndex: "2-3",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "排课管理",
              path: "/Home/CourseManager",
            },
            {
              name: "上课教室",
              path: "/Home/EditClassRoom",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/coursemanager/EditClassRoom.vue"),
      },
      {
        path: "editClassTime",
        name: "editClassTime",
        meta: {
          title: "上课时间",
          //对应的左侧menu的选中值
          defaultIndex: "2-3",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "排课管理",
              path: "/Home/CourseManager",
            },
            {
              name: "上课时间",
              path: "/Home/EditClassTime",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/coursemanager/EditClassTime.vue"),
      },
      {
        path: "ClassAwayRecord",
        name: "ClassAwayRecord",
        meta: {
          title: "课消记录",
          //对应的左侧menu的选中值
          defaultIndex: "2-4",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "课消记录",
              path: "/Home/ClassAwayRecord",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/classawayrecord/ClassAwayRecord.vue"),
      },
      {
        path: "AccountDetails",
        name: "AccountDetails",
        meta: {
          title: "账户明细",
          //对应的左侧menu的选中值
          defaultIndex: "2-5",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "账户明细",
              path: "/Home/AccountDetails",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/accountdetails/AccountDetails.vue"),
      },
      {
        path: "TeachingCenter",
        name: "TeachingCenter",
        meta: {
          title: "教学中心",
          //对应的左侧menu的选中值
          defaultIndex: "3",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教学中心",
              path: "/Home/TeachingCenter",
            },
          ],
        },
        component: () => import("@/views/teachingcenter/TeachingCenter.vue"),
      },
      {
        path: "MarketingCenter",
        name: "MarketingCenter",
        meta: {
          title: "营销中心",
          //对应的左侧menu的选中值
          defaultIndex: "4",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "营销中心",
              path: "/Home/MarketingCenter",
            },
          ],
        },
        component: () => import("@/views/marketingcenter/MarketingCenter.vue"),
      },
      {
        path: "TrainingCenter",
        name: "TrainingCenter",
        meta: {
          title: "培训中心",
          //对应的左侧menu的选中值
          defaultIndex: "5",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "培训中心",
              path: "/Home/TrainingCenter",
            },
          ],
        },
        component: () => import("@/views/trainingcenter/TrainingCenter.vue"),
      },
      {
        path: "UserManager",
        name: "UserManager",
        meta: {
          title: "用户管理",
          //对应的左侧menu的选中值
          defaultIndex: "6-0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "系统管理",
              path: "",
            },
            {
              name: "用户管理",
              path: "/Home/UserManager",
            },
          ],
        },
        component: () => import("@/views/systemmanagement/UserManager.vue"),
      },
      {
        path: "AddAccount",
        name: "AddAccount",
        meta: {
          title: "新增账号",
          //对应的左侧menu的选中值
          defaultIndex: "6-0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "系统管理",
              path: "",
            },
            {
              name: "用户管理",
              path: "/Home/UserManager",
            },
            {
              name: "新增账号",
              path: "/Home/AddAccount",
            },
          ],
        },
        component: () => import("@/views/systemmanagement/AddAccount.vue"),
      },
      {
        path: "UpdatePwd",
        name: "UpdatePwd",
        meta: {
          title: "修改密码",
          //对应的左侧menu的选中值
          defaultIndex: "6-0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "系统管理",
              path: "",
            },
            {
              name: "用户管理",
              path: "/Home/UserManager",
            },
            {
              name: "修改密码",
              path: "/Home/UpdatePwd",
            },
          ],
        },
        component: () => import("@/views/systemmanagement/UpdatePwd.vue"),
      },
      {
        path: "UserInfo",
        name: "UserInfo",
        meta: {
          title: "用户信息",
          //对应的左侧menu的选中值
          defaultIndex: "6-0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "系统管理",
              path: "",
            },
            {
              name: "用户管理",
              path: "/Home/UserManager",
            },
            {
              name: "用户信息",
              path: "/Home/UserInfo",
            },
          ],
        },
        component: () => import("@/views/systemmanagement/UserInfo.vue"),
      },
      {
        path: "EditSysAccount",
        name: "EditSysAccount",
        meta: {
          title: "编辑系统账户",
          //对应的左侧menu的选中值
          defaultIndex: "6-0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "系统管理",
              path: "",
            },
            {
              name: "用户管理",
              path: "/Home/UserManager",
            },
            {
              name: "编辑系统账户",
              path: "/Home/EditSysAccount",
            },
          ],
        },
        component: () => import("@/views/systemmanagement/EditSysAccount.vue"),
      },
      {
        path: "SysMessage",
        name: "SysMessage",
        meta: {
          title: "消息提醒",
          //对应的左侧menu的选中值
          defaultIndex: "6-1",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "系统管理",
              path: "",
            },
            {
              name: "消息提醒",
              path: "/Home/SysMessage",
            },
          ],
        },
        component: () => import("@/views/systemmanagement/SysMessage.vue"),
      },
      {
        path: "MySchool",
        name: "MySchool",
        meta: {
          title: "我的校区",
          //对应的左侧menu的选中值
          defaultIndex: "7",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "我的校区",
              path: "/Home/MySchool",
            },
          ],
        },
        component: () => import("@/views/myschool/MySchool.vue"),
      },
      {
        path: "DataCenter",
        name: "DataCenter",
        meta: {
          title: "数据中心",
          //对应的左侧menu的选中值
          defaultIndex: "8",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "数据中心",
              path: "/Home/DataCenter",
            },
          ],
        },
        component: () => import("@/views/datacenter/DataCenter.vue"),
      },
      {
        path: "CampusEdit",
        name: "CampusEdit",
        meta: {
          title: "新增校区",
          //对应的左侧menu的选中值
          defaultIndex: "0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "校区管理",
              path: "/Home/CampusManagement",
            },
            {
              name: "编辑校区",
              path: "/Home/CampusEdit",
            },
          ],
        },
        component: () => import("@/views/campusmanagement/CampusEdit.vue"),
      },
      {
        path: "LookCampusInfo",
        name: "LookCampusInfo",
        meta: {
          title: "查看校区",
          //对应的左侧menu的选中值
          defaultIndex: "0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "校区管理",
              path: "/Home/CampusManagement",
            },
            {
              name: "查看校区",
              path: "/Home/LookCampusInfo",
            },
          ],
        },
        component: () => import("@/views/campusmanagement/LookCampusInfo.vue"),
      },
      {
        path: "ManagerJoinLessons",
        name: "ManagerJoinLessons",
        meta: {
          title: "加盟课程管理",
          //对应的左侧menu的选中值
          defaultIndex: "0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "校区管理",
              path: "/Home/CampusManagement",
            },
            {
              name: "加盟课程管理",
              path: "/Home/ManagerJoinLessons",
            },
          ],
        },
        component: () => import("@/views/campusmanagement/ManagerJoinLessons.vue"),
      },
      {
        path: "ProjectMode",
        name: "ProjectMode",
        meta: {
          title: "模式切换",
          //对应的左侧menu的选中值
          defaultIndex: "0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "校区管理",
              path: "/Home/CampusManagement",
            },
            {
              name: "新建校区",
              path: "/Home/ProjectMode",
            },
          ],
        },
        component: () => import("@/views/campusmanagement/ProjectMode.vue"),
      },
      {
        path: "LookLessons",
        name: "LookLessons",
        meta: {
          title: "查看课程",
          //对应的左侧menu的选中值
          defaultIndex: "1",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "课程管理",
              path: "/Home/ProductManagement",
            },
            {
              name: "查看课程",
              path: "/Home/LookLessons",
            },
          ],
        },
        component: () => import("@/views/productmanagement/LookLessons.vue"),
      },
      {
        path: "LookTeacher",
        name: "LookTeacher",
        meta: {
          title: "查看教师信息",
          //对应的左侧menu的选中值
          defaultIndex: "2-0",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "教师管理",
              path: "/Home/TeacherManager",
            },
            {
              name: "查看教师信息",
              path: "/Home/LookTeacher",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/teachermanager/LookTeacher.vue"),
      },
      {
        path: "ClassDetail",
        name: "ClassDetail",
        meta: {
          title: "查看班级信息",
          //对应的左侧menu的选中值
          defaultIndex: "2-2",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "班级管理",
              path: "/Home/ClassManager",
            },
            {
              name: "班级管理详情",
              path: "/Home/ClassDetail",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/classmanager/ClassDetail.vue"),
      },
      {
        path: "ClassEvaluationAndAttendance",
        name: "ClassEvaluationAndAttendance",
        meta: {
          title: "考勤与评价",
          //对应的左侧menu的选中值
          defaultIndex: "2-2",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "班级管理",
              path: "/Home/ClassManager",
            },
            {
              name: "考勤与评价",
              path: "/Home/ClassEvaluationAndAttendance",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/classmanager/ClassEvaluationAndAttendance.vue"),
      },
      {
        path: "ManagerCourse",
        name: "ManagerCourse",
        meta: {
          title: "排课",
          //对应的左侧menu的选中值
          defaultIndex: "2-2",
          //配置顶部标题栏的数据
          titleArr: [
            {
              name: "教务中心",
              path: "",
            },
            {
              name: "班级管理",
              path: "/Home/ClassManager",
            },
            {
              name: "排课",
              path: "/Home/ManagerCourse",
            },
          ],
        },
        component: () => import("@/views/educationalcenter/classmanager/ManagerCourse.vue"),
      },

      //视频播放组件
      {
        path: "VideoList",
        name: "VideoList",
        meta: {
          // title: "排课",
          // //对应的左侧menu的选中值
          // defaultIndex: "2-2",
          // //配置顶部标题栏的数据
          // titleArr: [
          //   {
          //     name: "教务中心",
          //     path: "",
          //   },
          // ],
        },
        component: () => import("@/views/video/VideoList.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

//下面这段话是为了解决vue跳转到子路由时报错error:Redirected from via a navigation guard 报错信息的
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// VueRouter.prototype.push = function (location) {
//   return originalPush.call(this, location).catch((err) => err)
// }
export default router;

//获取Vue对象
let vm = Vue.prototype;

//这里使用路由守卫来判断用户是否登录用户信息是否存在,如果用户信息不存在,则跳转到首页登录界面
// router.beforeEach((to, from, next) => {
//   if (to.path != '/') {
//     // 判定userInfo是否存在
//     let userInfo = localStorage.getItem("userInfo")
//     if (vm.isEmpty(userInfo)) {
//       //如果没有用户信息则需要跳转到登录界面
//       localStorage.removeItem("autoLogin");
//       next('/')
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })
