// 根据Code生成不同报错信息的方法
exports.install = function (Vue, options) {
  //返回登录页的方法
  Vue.prototype.goLogin = function () {
    if (this.$route.path != "/") {
      //当前不是登录界面,则跳转到登录界面
      setTimeout(() => {
        localStorage.removeItem("userInfo");
        this.$router.replace("/");
      }, 1000);
    }
  };

  Vue.prototype.getMesage = function (code) {
    let res = "";
    switch (parseInt(code)) {
      case 101:
      case 104:
        res = "传入参数异常";
        break;
      case 105:
        res = "程序异常";
        break;
      case 106:
        res = "数据库更新失败";
        break;
      case 108:
        res = "您身份认证已失效,请重新登录！";
        break;
      case 112:
        res = "单个输入框最多输入500字";
        break;
      case 113:
        res = "错误数据";
        break;
      case 114:
        res = "数据不存在";
        break;
      case 115:
        res = "操作过于频繁";
        break;
      case 201:
        res = "账号或密码错误";
      case 202:
        res = "账号或密码错误";
        this.goLogin();
        break;
      case 203:
        res = "该账号已存在,请联系客服处理.";
        break;
      case 205:
        res = "手机号已被注册";
      case 214:
        res = "该账号已被锁定";
        break;
      case 403:
        res = "已存在同名班级";
        break;
      case 406:
        res = "排课数据有冲突";
        break;
      case 407:
        res = "请为班级选择课程";
        break;
      case 408:
        res = "有请先将学员移出该班级";
        break;

      case 409:
        res = "超过班级人数最大限制";
        break;
      case 411:
        res = "未到课程结束时间";
        break;
      case 413:
        res = "班级数量已达上限";
        break;
      case 417:
        res = "排课次数超过最大可排课次数";
        break;
      case 422:
        res = "已加入当前平台其他班级";
        break;
      case 425:
        res = "评价已推送完成,无法修改";
        break;
      case 426:
        res = "有未完成的排课，请先删除该班级未上的排课计划";
        break;
      case 427:
        res = "评价推送时间不能早于当前时间";
        break;
      case 428:
        res = "该班级已结课，请创建新班级进行排课";
        break;
      case 502:
        res = "学校合作已到期";
        this.goLogin();
        break;
      case 503:
        res = "校区已被禁用";
        this.goLogin();
        break;
      case 504:
        res = "学校合作已到期,操作无法生效！";
        this.goLogin();
        break;
      case 505:
        res = "账户余额不足";
        break;
      case 507:
        res = "校区已编辑权限";
        break;
      case 508:
        res = "该课程无试用账户,请重新选择";
        break;
      case 511:
        res = "校长手机号已注册过账号";
        break;
      case 512:
        res = "新增账户数不能为0，请至少新增1个账户";
        break;
      case 513:
        res = "校区名称已存在";
        break;
      case 514:
        res = "请先联系客服老师设置加盟课程";
        break;
      case 601:
        res = "此手机号在校区中已存在";
        break;
      case 603:
        res = "此手机号用户已加入其它校区";
        break;
      case 604:
        res = "正式生剩余课次数不可输入为0";
        break;
      case 605:
        res = "正式生不可修改为非正式生";
        break;
      case 606:
        res = "不能取消该学员所在班级已开放的课程";
        break;
      case 208:
        res = "您的账号已被挤下线,请重新登录~~";
        this.goLogin();
        break;
      case 214:
        res = "账号已被禁用";
        this.goLogin();
        break;

      case 219:
        res = "账号已过期";
        this.goLogin();
        break;
      case 221:
        res = "对方账户被禁用";
        break;
      case 108:
        res = "您的账号已被挤下线,请重新登录~~";
        this.goLogin();
        break;
      case 1001:
        res = "账号无权限访问此模块内容";
        this.goLogin();
        break;
    }
    return res;
  };
};
