//项目中用于保存数据的key值

//顶部标题栏的key
const TOP_TITLE_ARR = "top_title_arr"

// ----------------- 校区管理界面 ---------------------

//查看校区时跳转时保存校区消息的key 
const LOOK_SCHOOL_INFO = "LOOK_SCHOOL_INFO"

//加盟课程管理
const SCHOOL_INFO_STR = "schoolInfo_Str"

//校区管理跳转时的当前页码
const SCHOOL_MANAGER_CURRPAGE = "school_mangager_curr_page"

//校区管理跳转时的当前条数
const SCHOOL_MANAGER_CURR_PAGE_SIZE = "school_mangager_curr_pagesize"

//校区管理跳转时的加盟状态
const SCHOOL_MANAGER_STATE_VALUE = "school_mangager_state_value"

//校区管理跳转时的学校名称或者编码
const SCHOOL_MANAGER_SCHOOL_VALUE = "school_mangager_school_value"

//校区管理跳转时的联系电话
const SCHOOL_MANAGER_PHONE_VALUE = "school_mangager_phone_value"


//-------------- 用户管理界面 ----------------
//当前页面
const USER_MAGAGER_CURRPAGE = "USER_MAGAGER_CURRPAGE"

//当前的每页条数
const USER_MAGAGER_CURR_PAGE_SIZE = "USER_MAGAGER_CURR_PAGE_SIZE"

//账号关键字
const USER_MAGAGER_KEY_VALUE = "USER_MAGAGER_KEY_VALUE"


//退出时清空key值的方法
let clearKey = () => {
  localStorage.removeItem(LOOK_SCHOOL_INFO)
  localStorage.removeItem(SCHOOL_INFO_STR)
  localStorage.removeItem(SCHOOL_MANAGER_CURRPAGE)
  localStorage.removeItem(SCHOOL_MANAGER_CURR_PAGE_SIZE)
  localStorage.removeItem(SCHOOL_MANAGER_STATE_VALUE)
  localStorage.removeItem(SCHOOL_MANAGER_SCHOOL_VALUE)
  localStorage.removeItem(SCHOOL_MANAGER_PHONE_VALUE)
  sessionStorage.removeItem(TOP_TITLE_ARR);
  localStorage.removeItem(USER_MAGAGER_CURRPAGE);
  localStorage.removeItem(USER_MAGAGER_CURR_PAGE_SIZE);
  localStorage.removeItem(USER_MAGAGER_KEY_VALUE);
}

export default {
  TOP_TITLE_ARR,
  LOOK_SCHOOL_INFO,
  SCHOOL_INFO_STR,
  SCHOOL_MANAGER_CURRPAGE,
  SCHOOL_MANAGER_CURR_PAGE_SIZE,
  SCHOOL_MANAGER_STATE_VALUE,
  SCHOOL_MANAGER_SCHOOL_VALUE,
  SCHOOL_MANAGER_PHONE_VALUE,
  USER_MAGAGER_CURRPAGE,
  USER_MAGAGER_CURR_PAGE_SIZE,
  USER_MAGAGER_KEY_VALUE,
  clearKey
}
