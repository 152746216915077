import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import menu from './modules/menu'
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    menu
  }
})
