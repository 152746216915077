<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
//导入配置信息
import AppInfo from "./config/AppConig.js";
export default {
  data() {
    return {};
  },
  created() {
    this.initAppInfo();
  },
  methods: {
    //初始化网站标题和icon
    initAppInfo() {
      document.title = AppInfo.AppName;
      let link = document.querySelector('link[rel*="icon"]');
      link.setAttribute("href", AppInfo.AppIcon);
    },
  },
  components: {},
  watch: {
    // $route: {
    //     handler: function (to, from) {
    //         //获取跳转的路径
    //         let curPath = to.path;
    //         //发送事件给TopNav.vue同步顶部导航条按钮
    //         let that = this;
    //         that.$bus.emit("watchRoutePath", curPath);
    //     },
    //     // 深度观察监听
    //     deep: true,
    // },
  },
};
</script>
<style lang="scss" scoped>
#app {
  min-width: 1340px;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif;
}
// * {
//   -webkit-touch-callout: none; /*系统默认菜单被禁用*/
//   -webkit-user-select: none; /*webkit浏览器*/
//   -khtml-user-select: none; /*早期浏览器*/
//   -moz-user-select: none; /*火狐*/
//   -ms-user-select: none; /*IE10*/
//   user-select: none;
// }
</style>
