//可以多选的格子组件
<template>
    <div class="mult_grid_view">
        <div @click.stop="item.hasChoosed=!item.hasChoosed" :class="['item_view',{active:item.hasChoosed}]" v-for="(item,index) in gridArr" :key="index">{{item.name}}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {},
    components: {},
    props: ["gridArr"],
};
</script>
<style lang="scss" scoped>
.mult_grid_view {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    .item_view {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;
        border: 1px solid #ebeef5;
        border-radius: 4px;
        padding: 0 12px;
        height: 32px;
        cursor: pointer;
        margin-right: 14px;
    }

    .active {
        border: 1px solid #ff9901;
        background: #ff9901;
        color: white;
    }
}
</style>