//测试环境 8093端口
// const BASE_URL = "https://zk.zdw000.com/api_test/ycsj_control_center/";

//正式环境
const BASE_URL = "https://zk.zdwkj.com/api/ycsj_control_center/";

//登录模块接口
const LOGIN = BASE_URL + "login";

//校区管理列表
const SCHOOL_MANAGER = BASE_URL + "SchoolDistrictController";

//详细模式查询接口
const DETAILS_QUERY = BASE_URL + "schoolManage";

//课程管理模块
const SUBJECT_MANAGER = BASE_URL + "SubjectManageController";

//教务中心 教师管理接口
const TEACHERMANAGER = BASE_URL + "educationManage";

//教务中心 班级管理接口
const CLASSMANAGER = BASE_URL + "classManage";

//教务中心 账户明细
const ACCOUNTDETAILS = BASE_URL + "educationManage";

//获取学校加盟的课程
const ALLLESSONS = BASE_URL + "SubjectManageController";

//查询校区权限
const SCHOOL_JURISDICTION = BASE_URL + "schoolManage";

const StudentManageController = BASE_URL + "StudentManageController";

//排课管理n` or `jsconfig.json` in
const LESSONMANAGER = BASE_URL + "curriculumAdmin";

//课消记录
const CLASSWAYRECORD = BASE_URL + "ClassCancellation";

//用户管理
const ADMINMANAGE = BASE_URL + "adminManage";

//系统消息
const MESSAGEREMINDER = BASE_URL + "MessageReminder";

//权限判定
const PERMISSION = BASE_URL + "login";

//校区重置密码
const SCHOOLRESTPWD = BASE_URL + "SchoolDistrictController";

//老师重置密码
const TEACHERRESTPWD = BASE_URL + "educationManage";

//评价应用全班接口
const ApplyAllClass = BASE_URL + "api";

//数据中心
const DATA_CENTER = BASE_URL + "dataCenter";

export default {
  LOGIN,
  SCHOOL_MANAGER,
  DETAILS_QUERY,
  TEACHERMANAGER,
  SUBJECT_MANAGER,
  CLASSMANAGER,
  ACCOUNTDETAILS,
  ALLLESSONS,
  SCHOOL_JURISDICTION,
  StudentManageController,
  LESSONMANAGER,
  CLASSWAYRECORD,
  ADMINMANAGE,
  MESSAGEREMINDER,
  PERMISSION,
  SCHOOLRESTPWD,
  TEACHERRESTPWD,
  ApplyAllClass,
  DATA_CENTER
};
